import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4>{`Description`}</h4>
    <p>{`I worked at IPMA, the portuguese weather authority, on its remote sensing
team in the Copernicus Global Land project and its percursor projects
(Geoland-2, GMES). `}</p>
    <p>{`The Copernicus Global Land Service is a component of `}<ExternalLink href="https://www.copernicus.eu/" mdxType="ExternalLink">{`Copernicus`}</ExternalLink>{`,
the European flagship programme on Earth Observation. `}</p>
    <p>{`IPMA's role in this project was:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`To generate radiation products from geostationary satellites in
order to obtain global coverage`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Make these products available to end users by providing the necessary
interfaces in order for them to appear at centralized product navigation
portals, together with other remote sensing products`}</p>
      </li>
    </ul>
    <h4>{`My role`}</h4>
    <p>{`My contribution to project was:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Designed and implemented processing lines for product generation and
distribution - these were implemented with Python and ECMWF's ecFlow scheduler.`}</p>
        <p parentName="li">{`The system operated continouously, in near realtime, and most products were
generated with an hourly frequency.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setup metadata catalog for generated products according to OGC CSW
standard. This was implemented with pycsw and it eventually led to my
involvment with the pycsw project.`}</p>
        <p parentName="li">{`The metadata schema itself was based on the `}<ExternalLink href="https://www.iso.org/standard/26020.html" mdxType="ExternalLink">{`ISO 19115`}</ExternalLink>{`
standard and compliant with the `}<ExternalLink href="https://inspire.ec.europa.eu/metadata/6541" mdxType="ExternalLink">{`INSPIRE directive guidelines`}</ExternalLink>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Designed and implemented a product ordering service compliant with OGC OSEO
standard - This was implemented as a reusable django application and was the
first (probably still the only one today) open source implementation of this
(rather obscure) OGC standard `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Designed and implemented a quality monitoring service that extracted
multiple statistical indicators for each generated product and made them
available via a REST API`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      